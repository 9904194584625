import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { usePlatforms, usePlatformSelector } from "hooks/dev";
import { Layout } from "components/common/Layout";
import { Button, Container } from "components/common";
import { Platform, SkeletonPlatform } from "components/Platforms/Platform";
import { AddToGroupModal } from "components/Modal/AddToGroupModal";
import { useDeletePlatforms } from "hooks/business";

export const Accounts = () => {
  const { t } = useTranslation();
  const { platforms, isLoading } = usePlatforms({ checkAuth: false });
  const { selectedPlatforms, onChangeSelectedPlatforms } =
    usePlatformSelector();
  const [isOpenAddToGroup, setIsOpenAddToGroup] = useState(false);

  const { onDeletePlatforms } = useDeletePlatforms();

  const onDelete = useCallback(() => {
    onDeletePlatforms({
      platforms: Object.entries(selectedPlatforms)
        .filter(([_, isSelected]) => isSelected)
        .map(([id]) => id),
    });
  }, [onDeletePlatforms, selectedPlatforms]);

  const isDisabledDelete = !Object.values(selectedPlatforms).some(Boolean);
  const isDisabledAdd =
    Object.values(selectedPlatforms).filter(Boolean).length < 2;

  return (
    <Layout>
      <Container>
        <div className="pt-[20px] w-full h-full flex flex-col">
          <div className="text-3xl text-main mb-4 font-bold">
            {t("accounts", { defaultValue: "Accounts" })}
          </div>
          <div className="text-xl font-medium mb-3">
            {t("select-accounts", "Select Accounts")}:
          </div>
          {isLoading ? (
            <div className="flex gap-3 py-2">
              <SkeletonPlatform />
              <SkeletonPlatform />
              <SkeletonPlatform />
            </div>
          ) : (
            <div className="flex gap-3 flex-wrap">
              {(platforms || []).map((platform) => (
                <Platform
                  key={platform.id}
                  onClick={() => onChangeSelectedPlatforms(platform.id)}
                  isSelected={selectedPlatforms?.[platform.id]}
                  {...platform}
                />
              ))}
            </div>
          )}
          <div className="mt-10 w-full flex gap-2">
            {/* <Button
              disabled={isDisabledAdd}
              onClick={() => setIsOpenAddToGroup(true)}
            >
              {t("add-to-group", "Add to Group")}
            </Button> */}
            <Button disabled={isDisabledDelete} onClick={onDelete}>
              {t("remove-connection", "Remove Connection")}
            </Button>
          </div>
        </div>
        <AddToGroupModal
          isOpen={isOpenAddToGroup}
          onClose={() => setIsOpenAddToGroup(false)}
          selectedPlatforms={selectedPlatforms}
        />
      </Container>
    </Layout>
  );
};
