export const Checkbox = ({ label, value, onChange, className }) => {
  return (
    <div className={["relative", className].filter(Boolean).join(" ")}>
      <div
        className="flex items-center cursor-pointer gap-2"
        onClick={() => onChange(!value)}
      >
        <label
          className="relative flex cursor-pointer items-center rounded-full"
          htmlFor="checkbox"
          data-ripple-dark="true"
        >
          <input
            type="checkbox"
            className="before:content[''] bg-white peer relative h-5 w-5 cursor-pointer appearance-none border-2 border-line transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-6 before:w-6 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:opacity-0 before:transition-opacity checked:border-main checked:bg-main checked:before:bg-main"
            id="checkbox"
            checked={value}
            onChange={(e) => {}}
          />
          <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-acent opacity-0 transition-opacity peer-checked:opacity-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-3.5 w-3.5"
              viewBox="0 0 20 20"
              fill="currentColor"
              stroke="currentColor"
              strokeWidth="1"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
        </label>
        <div className="text-main text-sm">{label}</div>
      </div>
    </div>
  );
};
