import { useQuery } from "@tanstack/react-query";
import { useX } from "hooks/auth/useX";
import { useYouTube } from "hooks/auth/useYouTube";
import { useFacebook } from "hooks/auth/useFacebook";
import { useTikTok } from "hooks/auth/useTikTok";
import { useCallback, useMemo, useState } from "react";

const queryFn = async () =>
  fetch("/api/private/platform/platforms")
    .then((res) => res.json())
    .then((res) => {
      if (res.success) return res.data;
      return [];
    });

export const usePlatformsPure = () =>
  useQuery(["GET_USER_PLATFORMS"], queryFn, { staleTime: Infinity });

export const usePlatforms = ({ checkAuth = false }) => {
  const { data, isLoading, refetch: refetchPlatforms } = usePlatformsPure();
  useX({
    onSuccess: refetchPlatforms,
    checkAuth,
  });
  useYouTube({
    onSuccess: refetchPlatforms,
    checkAuth,
  });
  useFacebook({
    onSuccess: refetchPlatforms,
    checkAuth,
  });
  useTikTok({
    onSuccess: refetchPlatforms,
    checkAuth,
  });

  return { platforms: data, isLoading, refetchPlatforms };
};

export const useAuthPlatforms = ({ checkAuth = false }) => {
  const { data, refetch: refetchPlatforms } = usePlatformsPure();
  const { onAuth: xOnAuth, xIsLoading } = useX({
    onSuccess: refetchPlatforms,
    checkAuth,
  });
  const { onAuth: youtubeOnAuth, youtubeIsLoading } = useYouTube({
    onSuccess: refetchPlatforms,
    checkAuth,
  });
  const { facebookAuth, instagramAuth, facebookIsLoading, instagramIsLoading } =
    useFacebook({
      onSuccess: refetchPlatforms,
      checkAuth,
    });
  const { onAuth: tiktokOnAuth, tiktokIsLoading } = useTikTok({
    onSuccess: refetchPlatforms,
    checkAuth,
  });

  const getAuthFnByPlatform = useMemo(
    () => ({
      X: xOnAuth,
      YouTube: youtubeOnAuth,
      Facebook: facebookAuth,
      Instagram: instagramAuth,
      TikTok: tiktokOnAuth,
    }),
    [xOnAuth, youtubeOnAuth, facebookAuth, instagramAuth, tiktokOnAuth]
  );

  const getIsLoadingByPlatform = useMemo(
    () => ({
      X: xIsLoading,
      YouTube: youtubeIsLoading,
      Instagram: instagramIsLoading,
      Facebook: facebookIsLoading,
      TikTok: tiktokIsLoading,
    }),
    [
      instagramIsLoading,
      facebookIsLoading,
      xIsLoading,
      youtubeIsLoading,
      tiktokIsLoading,
    ]
  );

  return { platforms: data, getAuthFnByPlatform, getIsLoadingByPlatform };
};

export const usePlatformSelector = () => {
  const [selectedPlatforms, setSelectedPlatforms] = useState({});

  const onChangeSelectedPlatforms = useCallback((id) => {
    setSelectedPlatforms((values) => ({
      ...values,
      [id]: !Boolean(values?.[id]),
    }));
  }, []);

  return { selectedPlatforms, onChangeSelectedPlatforms };
};
