import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useConfirm } from "hooks/dev/useConfirm";

export const useCancelSchedule = () => {
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate } = useMutation({
    mutationFn: (id) =>
      fetch(`/api/private/post/cancelSchedule?id=${id}`).then((res) =>
        res.json()
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["GET_POSTS_HISTORY"] });
      toast.success(t("success", "Success"));
    },
    onError: () => {
      toast.error(t("failed", "Failed"));
    },
  });

  const onCancelSchedule = (id) => {
    confirm({
      title: t("cancel-schedule", "Cancel Schedule"),
      description: t(
        "cancel-schedule-description",
        "Are you sure you want to cancel this schedule?"
      ),
      onSubmit: () => mutate(id),
    });
  };

  return { onCancelSchedule };
};
