import React, { useRef } from "react";

export const DateInput = ({ label, value, onChange, id, type, min }) => {
  const dateInputRef = useRef(null);

  const handleClick = () => {
    dateInputRef.current.focus();
    dateInputRef.current.showPicker();
  };

  return (
    <div className="relative cursor-pointer" onClick={handleClick}>
      <label
        className="absolute font-semibold text-main break-words cursor-default pointer-events-none text-xs pl-[12px] pt-[6px] z-10"
        forhtml={id}
        id={`${id}-label`}
      >
        {label}
      </label>
      <div className="relative">
        <input
          min={min}
          ref={dateInputRef}
          className="appearance-none resize-none box-border text-[18px] w-full text-black block text-left px-[12px] pt-[18px] border-2 border-line bg-white transition-border-color duration-100 ease-in-out min-h-[60px] hover:border-main focus:outline-none"
          id={id}
          type={type}
          aria-invalid="false"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    </div>
  );
};
