import { useContext, useMemo, useCallback } from "react";
import { PaywallContext } from "components/Providers/PaywallContext";
import { useUser } from "./useUser";

export const usePaywall = () => useContext(PaywallContext);

export const useCheckLimit = (action) => {
  const { userInfo } = useUser();
  const { showPaywall } = usePaywall();

  const limit = useMemo(() => {
    switch (action) {
      case "ACCOUNT":
        return userInfo.limits.accountsLimit;
      case "POST":
        return userInfo.limits.postLimit;
      case "REPOST":
        return userInfo.limits.repostLimit;
      default:
        return 0;
    }
  }, [action, userInfo.limits]);

  const executeFnWithCheckLimit = useCallback(
    (callback) => {
      if (limit <= 0) return showPaywall();
      return callback();
    },
    [limit, showPaywall]
  );

  return { executeFnWithCheckLimit };
};
