import { ModalV2, Button } from "components/common";
import { useTranslation } from "react-i18next";

export const ConfirmModal = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  description,
}) => {
  const { t } = useTranslation();

  return (
    <ModalV2
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <div className="flex justify-end gap-2">
          <Button
            onClick={onClose}
            className="bg-third border border-main !text-main w-full md:w-fit"
          >
            {t("cancel", "Cancel")}
          </Button>
          <Button onClick={onSubmit} className="w-full md:w-fit">
            {t("submit", "Submit")}
          </Button>
        </div>
      }
    >
      <div>
        <div className="text-md mt-1 text-main">{description}</div>
      </div>
    </ModalV2>
  );
};
