import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Close } from "components/icons";
import { Button } from "../common";
import { useUser, usePaywall } from "hooks/dev";
import { Logo } from "components/icons/Logo";
import { PAYMENT_CONFIG } from "constants";

const FEATURES_CONFIG = {
  "x-connected-accounts": {
    translationKey: "x-connected-accounts",
    defaultValue: "{{n}} Connected Accounts",
    formatParams: (params) => ({
      n: params.count,
      ...params,
    }),
    ui: {
      bold: true,
      important: true,
    },
  },
  "x-repost-accounts": {
    translationKey: "x-repost-accounts",
    defaultValue: "{{n}} Repost Accounts",
    formatParams: (params) => ({
      n: params.count,
      ...params,
    }),
  },
  "unlimited-posts": {
    translationKey: "unlimited-posts",
    defaultValue: "Unlimited Posts",
  },
  "schedule-posts": {
    translationKey: "schedule-posts",
    defaultValue: "Schedule Posts",
  },
  "unlimited-accounts": {
    translationKey: "unlimited-accounts",
    defaultValue: "Unlimited Accounts ⭐️",
    ui: {
      bold: true,
      important: true,
    },
  },
  "unlimited-reposts": {
    translationKey: "unlimited-reposts",
    defaultValue: "Unlimited Reposts ⭐️",
    ui: {
      bold: true,
      important: true,
    },
  },
};

const formatFeature = (t, featureKey, params = {}) => {
  const feature = FEATURES_CONFIG[featureKey];
  const translationParams = feature.formatParams
    ? feature.formatParams(params)
    : {};

  return {
    title: t(feature.translationKey, feature.defaultValue, translationParams),
    ...feature.ui,
  };
};

const priceConfig = (t) => [
  {
    key: "STARTER",
    title: t("starter", "Starter"),
    features: [
      { key: "x-connected-accounts", params: { count: 5 } },
      { key: "unlimited-posts" },
      { key: "schedule-posts" },
    ].map(({ key, params }) => formatFeature(t, key, params)),
  },
  {
    key: "INFLUENCER",
    title: t("influencer", "Influencer"),
    features: [
      { key: "x-connected-accounts", params: { count: 10 } },
      { key: "x-repost-accounts", params: { count: 5 } },
      { key: "unlimited-posts" },
      { key: "schedule-posts" },
    ].map(({ key, params }) => formatFeature(t, key, params)),
  },
  {
    key: "PRO",
    title: t("pro", "Pro"),
    features: [
      { key: "unlimited-accounts" },
      { key: "unlimited-reposts" },
      { key: "unlimited-posts" },
      { key: "schedule-posts" },
    ].map(({ key, params }) => formatFeature(t, key, params)),
  },
];

export const Paywall = () => {
  const [selected, setSelected] = useState("INFLUENCER");
  const [billingPeriod, setBillingPeriod] = useState("M");
  const { userInfo } = useUser();
  const { t } = useTranslation();
  const { isOpen, hidePaywall } = usePaywall();

  const plans = priceConfig(t);

  const onSubmit = () => {
    const selectedPlan = PAYMENT_CONFIG[selected][billingPeriod];
    if (selectedPlan) {
      window.location.href = `${selectedPlan?.link}?client_reference_id=${userInfo?.userId}`;
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[200] w-full min-h-[calc(100dvh)] bg-third">
      <div className="relative h-full lg:grid lg:grid-cols-2 lg:gap-2">
        <div className="flex flex-col h-full">
          <div className="p-6 flex-shrink-0">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">
                {t("subscribe", "Subscribe")}
              </h2>
              <div onClick={hidePaywall}>
                <Close className="w-5 h-5 cursor-pointer" />
              </div>
            </div>
            <div className="flex justify-center items-center space-x-4 mt-4 mb-2">
              <button
                className={`px-4 py-2 rounded-lg ${
                  billingPeriod === "M"
                    ? "bg-main text-white"
                    : "bg-white border-main border"
                }`}
                onClick={() => setBillingPeriod("M")}
              >
                {t("monthly", "Monthly")}
              </button>
              <button
                className={`px-4 py-2 rounded-lg ${
                  billingPeriod === "Y"
                    ? "bg-main text-white"
                    : "bg-white border-main border"
                } relative`}
                onClick={() => setBillingPeriod("Y")}
              >
                {t("yearly", "Yearly")}
                <span className="absolute -top-4 -right-14 bg-green-500 text-white text-xs px-2 py-1 rounded-full whitespace-nowrap">
                  {t("two-month-free", "2 month free")}
                </span>
              </button>
            </div>
          </div>

          <div className="flex-grow overflow-y-auto px-6">
            <div className="space-y-3 mb-6">
              {plans.map((plan, planIdx) => (
                <div
                  key={plan.key}
                  onClick={() => setSelected(plan.key)}
                  className={`p-4 rounded-lg border cursor-pointer relative ${
                    plan.key === selected
                      ? "border-main bg-main text-acent"
                      : "border-gray-200"
                  }`}
                >
                  {planIdx === 1 && (
                    <span className="animate-pulse absolute bottom-4 right-4 bg-yellow-500 text-white text-xs px-2 py-1 rounded-full whitespace-nowrap">
                      {t("most popular", "Most Popular")}
                    </span>
                  )}
                  <div className="flex justify-between items-center mb-2">
                    <span className="font-semibold text-xl">{plan.title}</span>
                    <div
                      className={`font-bold text-3xl ${
                        plan.key === selected ? "text-acent" : "text-main"
                      }`}
                    >
                      ${PAYMENT_CONFIG[plan.key][billingPeriod].price}
                      <span className="text-gray-500 font-normal text-sm">
                        /{billingPeriod === "Y" ? "year" : "month"}
                      </span>
                    </div>
                  </div>
                  <div className="space-y-2">
                    {plan.features.map((feature, idx) => (
                      <div
                        key={`${idx}_0`}
                        className={`flex items-center ${
                          feature.important
                            ? "text-main font-bold"
                            : "text-gray-600"
                        } ${
                          plan.key === selected ? "text-white" : "text-main"
                        }`}
                      >
                        <svg
                          className="w-5 h-5 mr-2 text-green-500"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                        {feature.title}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="p-6 flex-shrink-0 flex gap-5">
            <Button
              className="w-full bg-third border border-main !text-main"
              onClick={hidePaywall}
            >
              {t("cancel", "Cancel")}
            </Button>
            <Button className="w-full" onClick={onSubmit}>
              {t("subscribe", "Subscribe")}
            </Button>
          </div>
        </div>
        <div className="hidden lg:flex border-0 border-solid border-l-[1px] bg-main justify-center items-center">
          <Logo className="w-[200px] text-acent" />
        </div>
      </div>
    </div>
  );
};
