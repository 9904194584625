export const ArrowRight = ({ className }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <path
        d="M5 17H27C27.5523 17 28 16.5523 28 16C28 15.4477 27.5523 15 27 15H5C4.44772 15 4 15.4477 4 16C4 16.5523 4.44772 17 5 17Z"
        fill="current"
      />
      <path
        d="M25.5858 16L17.2929 24.2929C17.1054 24.4804 17 24.7348 17 25C17 25.2652 17.1054 25.5196 17.2929 25.7071C17.4804 25.8946 17.7348 26 18 26C18.2652 26 18.5196 25.8946 18.7071 25.7071L27.7071 16.7071C28.0976 16.3166 28.0976 15.6834 27.7071 15.2929L18.7071 6.29289C18.5196 6.10536 18.2652 6 18 6C17.7348 6 17.4804 6.10536 17.2929 6.29289C17.1054 6.48043 17 6.73478 17 7C17 7.26522 17.1054 7.51957 17.2929 7.70711L25.5858 16Z"
        fill="current"
      />
    </svg>
  );
};
