export const Warning = ({ className }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <path
        d="M15 13.0002V18.0002C15 18.5525 15.4477 19.0002 16 19.0002C16.5523 19.0002 17 18.5525 17 18.0002V13.0002C17 12.448 16.5523 12.0002 16 12.0002C15.4477 12.0002 15 12.448 15 13.0002Z"
        fill="current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4096 4.49928C13.4096 4.49928 13.8084 3.80494 14.5014 3.4031C14.5014 3.4031 15.1965 3 16 3C16 3 16.8036 3 17.4987 3.4031C17.4987 3.4031 18.1938 3.8062 18.593 4.50362L29.5905 23.4993C29.5905 23.4993 29.9923 24.1952 29.993 24.9977C29.993 24.9977 29.9937 25.8001 29.5937 26.4957C29.5937 26.4957 29.1937 27.1914 28.4999 27.5944C28.4999 27.5944 27.806 27.9975 27.0036 28.0003L5.00004 28.0003C5.00004 28.0003 4.19409 27.9975 3.50022 27.5944C3.50022 27.5944 2.80635 27.1914 2.40636 26.4957C2.40636 26.4957 2.00637 25.8001 2.00708 24.9977C2.00708 24.9977 2.00779 24.1952 2.40902 23.5003L13.4071 4.50361L13.4096 4.49928ZM4.14106 24.5003L15.1403 5.50159L15.1429 5.49714L15.1434 5.49617C15.1434 5.49617 15.2753 5.26624 15.5047 5.13324C15.5047 5.13324 15.7344 5 16 5C16 5 16.2656 5 16.4954 5.13324C16.4954 5.13324 16.7252 5.26648 16.8571 5.49701L27.8596 24.5014C27.8596 24.5014 27.9928 24.732 27.993 24.9994C27.993 24.9994 27.9932 25.2669 27.8599 25.4988C27.8599 25.4988 27.7266 25.7307 27.4953 25.865C27.4953 25.865 27.264 25.9994 26.9965 26.0003L5.00004 26.0003C5.00004 26.0003 4.73608 25.9994 4.50479 25.865C4.50479 25.865 4.2735 25.7307 4.14017 25.4988C4.14017 25.4988 4.00684 25.2669 4.00708 24.9994C4.00708 24.9994 4.00732 24.732 4.14106 24.5003Z"
        fill="current"
      />
      <path
        d="M17.5 22.5002C17.5 23.3287 16.8284 24.0002 16 24.0002C15.1716 24.0002 14.5 23.3287 14.5 22.5002C14.5 21.6718 15.1716 21.0002 16 21.0002C16.8284 21.0002 17.5 21.6718 17.5 22.5002Z"
        fill="current"
      />
    </svg>
  );
};
