import { Platform, SkeletonPlatform } from "./Platform";
import { usePlatforms } from "hooks/dev/usePlatforms";
import { FacebookAuthModal } from "components/Modal/FacebookAuthModal";
import { InstagramAuthModal } from "components/Modal/InstagramAuthModal";
import { AuthPlatform } from "./AuthPlatform";
import { ExpiredPlatforms } from "./ExpiredPlatforms";

export const Platforms = ({ selectedPlatforms, onChangeSelectedPlatforms }) => {
  const { platforms, isLoading, refetchPlatforms } = usePlatforms({
    checkAuth: true,
  });

  if (isLoading)
    return (
      <div className="flex gap-3 py-2">
        <SkeletonPlatform />
        <SkeletonPlatform />
        <SkeletonPlatform />
      </div>
    );

  return (
    <>
      <div className="relative">
        <div className="flex gap-3 overflow-x-auto scrollbar-hide py-2 md:flex-wrap md:max-w-[700px]">
          {(platforms || [])
            .filter((platform) => platform.status === "CONNECTED")
            .map((platform) => (
              <Platform
                key={platform.id}
                onClick={() => onChangeSelectedPlatforms(platform.id)}
                isSelected={selectedPlatforms?.[platform.id]}
                {...platform}
              />
            ))}
          <ExpiredPlatforms />
          <AuthPlatform />
        </div>
        <div className="absolute w-[20px] h-[66px] top-0 right-0 bg-gradient-to-r from-transparent  to-third" />
      </div>
      <FacebookAuthModal onSuccess={refetchPlatforms} />
      <InstagramAuthModal onSuccess={refetchPlatforms} />
    </>
  );
};
