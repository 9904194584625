export const isDev = process.env.REACT_APP_IS_DEV === "true";

export const FE_HOST = isDev
  ? "https://www.localhost:8062"
  : "https://app.taisly.com";

export const PAYMENT_CONFIG = {
  STARTER: {
    M: {
      price: 7,
      link: isDev
        ? "https://buy.stripe.com/test_4gweXH7sQ6rYcDKfYY"
        : "https://buy.stripe.com/eVabL845h2wyc2k9AD",
      plink: isDev
        ? "plink_1QR6QYGJeOREexyvFAsA0s15"
        : "plink_1QR6QYGJeOREexyvFAsA0s15",
    },
    Y: {
      price: 70,
      link: isDev
        ? "https://buy.stripe.com/aEU16u1X9fjkfewaEL"
        : "https://buy.stripe.com/aEU16u1X9fjkfewaEL",
      plink: isDev
        ? "plink_1QR6hfGJeOREexyvh1WDfVCk"
        : "plink_1QR6hfGJeOREexyvh1WDfVCk",
    },
    accountsLimit: 5,
    repostLimit: 0,
  },
  INFLUENCER: {
    M: {
      price: 13,
      link: isDev
        ? "https://buy.stripe.com/test_5kA7vf4gE8A68nuaEF"
        : "https://buy.stripe.com/14k6qO1X9efgfew6ot",
      plink: isDev
        ? "plink_1QRE9TGJeOREexyvmZaWwEuz"
        : "plink_1QR6chGJeOREexyvZXSRPE8R",
    },
    Y: {
      price: 130,
      link: isDev
        ? "https://buy.stripe.com/14k5mKatF5IK2rK5kq"
        : "https://buy.stripe.com/14k5mKatF5IK2rK5kq",
      plink: isDev
        ? "plink_1QR6g0GJeOREexyvPGt474a2"
        : "plink_1QR6g0GJeOREexyvPGt474a2",
    },
    accountsLimit: 10,
    repostLimit: 5,
  },
  PRO: {
    M: {
      price: 19,
      link: isDev
        ? "https://buy.stripe.com/dR65mKatFc780jC9AI"
        : "https://buy.stripe.com/dR65mKatFc780jC9AI",
      plink: isDev
        ? "plink_1QR6lfGJeOREexyvN5oMGWWX"
        : "plink_1QR6lfGJeOREexyvN5oMGWWX",
    },
    Y: {
      price: 190,
      link: isDev
        ? "https://buy.stripe.com/fZe6qO8lxefg6I03cl"
        : "https://buy.stripe.com/fZe6qO8lxefg6I03cl",
      plink: isDev
        ? "plink_1QR6pOGJeOREexyvjEW7Zfvs"
        : "plink_1QR6pOGJeOREexyvjEW7Zfvs",
    },
    accountsLimit: 999,
    repostLimit: 999,
  },
};

export const LIMIT = 100;

export const META_AUTH_LINK = `https://www.facebook.com/v18.0/dialog/oauth?client_id=6839380202789530&redirect_uri=${
  isDev ? "https://localhost:8062/post" : "https://app.taisly.com/post"
}&scope=pages_show_list,pages_manage_posts,instagram_content_publish,business_management,pages_read_engagement,instagram_basic&auth_type=rerequest`;

export const PLATFORMS_LIST = [
  "Instagram",
  "TikTok",
  "YouTube",
  "X",
  "Facebook",
];

export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const localesMap = {
  en: {
    name: "English",
  },
  de: {
    name: "Deutsch",
  },
  th: {
    name: "ไทย",
  },
  // es: {
  //   name: "Español",
  // },
  // fr: {
  //   name: "Français",
  // },
  // "pt-PT": {
  //   name: "Português (Portugal)",
  // },
};
