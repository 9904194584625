import { useErrorAddPlatformModal } from "components/Modal/ErrorAddPlatformModal";
import { FE_HOST } from "constants";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useX = ({ onSuccess, checkAuth }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { search } = useLocation();
  const isActivating = useRef(false);
  const queryParams = new URLSearchParams(search);
  const navigate = useNavigate();
  const { showErrorReasonModal } = useErrorAddPlatformModal();

  const auth = queryParams.get("auth");

  const onFinishAuth = useCallback(() => {
    window.localStorage.setItem("platform-auth-process", "false");
    setIsLoading(false);
  }, []);

  const onAccount = useCallback(
    (oauth_token, oauth_verifier) => {
      fetch(
        `/api/private/platform/x/addUser?oauth_token=${oauth_token}&oauth_verifier=${oauth_verifier}`
      )
        .then((data) => data.json())
        .then((data) => {
          if (data.success) {
            onSuccess();
          } else {
            showErrorReasonModal({ platform: "X" });
            toast.error(t(data.message || "be-error-default"));
          }
          onFinishAuth();
          navigate(`/post`);
        })
        .catch(() => onFinishAuth());
    },
    [t, onSuccess, navigate, onFinishAuth]
  );

  const onCheck = useCallback(() => {
    const authPlatform = window.localStorage.getItem("platform-auth-process");
    if (authPlatform === "X") {
      setIsLoading(true);
      isActivating.current = true;
      const oauth_token = queryParams.get("oauth_token");
      const oauth_verifier = queryParams.get("oauth_verifier");
      onAccount(oauth_token, oauth_verifier);
    }
  }, [checkAuth]);

  useEffect(() => {
    if (checkAuth && !isActivating.current) onCheck();
  }, [checkAuth, !isActivating.current, onCheck]);

  const onAuth = useCallback(() => {
    setIsLoading(true);
    window.localStorage.setItem("platform-auth-process", "X");
    fetch(
      `/api/private/platform/x/getToken?callback=${FE_HOST}/post?auth=twitter`
    )
      .then((data) => data.json())
      .then((data) => {
        if (data.token) {
          window.location.replace(
            `https://api.twitter.com/oauth/authenticate?oauth_token=${data.token}`
          );
        } else {
          onFinishAuth();
          toast.error(t(data.message || "be-error-default"));
        }
      })
      .catch(() => onFinishAuth());
  }, [t, onFinishAuth]);

  return { onAuth, xIsLoading: isLoading };
};
