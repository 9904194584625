import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useConfirm } from "hooks/dev/useConfirm";

const queryFn = async (body) =>
  fetch("/api/private/platform/deletePlatforms", {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());

export const useDeletePlatforms = () => {
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const { t } = useTranslation();

  const { mutate } = useMutation({
    mutationKey: ["DELETE_PLATFORMS"],
    mutationFn: queryFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["GET_USER_PLATFORMS"] });
      toast.success(t("success", "Success"));
    },
    onError: () => {
      toast.error(t("failed", "Failed"));
    },
  });

  const onDeletePlatforms = (platformData) => {
    confirm({
      title: t("remove-connection", "Remove Connection"),
      description: t(
        "remove-connection-description",
        "All scheduled posts using this account will not be posted"
      ),
      onSubmit: () => mutate(platformData),
    });
  };

  return { onDeletePlatforms };
};
