import { useState, useMemo, useCallback } from "react";
import { Platform } from "./Platform";
import { ModalV2, Button } from "components/common";
import { useTranslation, Trans } from "react-i18next";
import { useAuthPlatforms } from "hooks/dev";
import { Warning } from "components/icons";

export const ExpiredPlatforms = () => {
  const { t } = useTranslation();
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const { platforms, getAuthFnByPlatform, getIsLoadingByPlatform } =
    useAuthPlatforms({
      checkAuth: false,
    });

  const onConnect = useCallback(() => {
    if (!selectedPlatform?.platform) return () => {};
    return getAuthFnByPlatform[selectedPlatform?.platform]();
  }, [getAuthFnByPlatform, selectedPlatform?.platform]);

  const isLoading = useMemo(() => {
    if (!selectedPlatform?.platform) return false;
    return getIsLoadingByPlatform[selectedPlatform?.platform];
  }, [getIsLoadingByPlatform, selectedPlatform?.platform]);

  return (
    <>
      {(platforms || [])
        .filter((platform) => platform.status === "EXPIRED")
        .map((platform) => (
          <Platform
            key={platform.id}
            onClick={() =>
              setSelectedPlatform({
                platform: platform.platform,
                username: platform.username,
              })
            }
            {...platform}
          />
        ))}
      <ModalV2
        isOpen={Boolean(selectedPlatform)}
        onClose={() => setSelectedPlatform(null)}
        title={`${t("reconnect-platform", {
          defaultValue: "Reconnect {{platform}}",
          platform: selectedPlatform?.username,
        })}`}
        footer={
          <div className="flex w-full md:justify-end">
            <Button
              className="w-full md:w-fit"
              onClick={onConnect}
              isLoading={isLoading}
              disabled={isLoading}
            >
              {t("reconnect", "Reconnect")}
            </Button>
          </div>
        }
      >
        <div className="mb-2">
          <Trans
            i18nKey="reconnect-platform-description"
            defaults="Authorization has expired. Please reconnect your account to continue sharing your videos"
            values={{
              account: selectedPlatform?.username,
              platform: selectedPlatform?.platform,
            }}
            components={{
              b: <b className="font-bold" />,
            }}
          />
        </div>
        <div className="flex items-center gap-2">
          <Warning className="size-4 min-w-4" />
          <div>
            <Trans
              i18nKey="reconnect-platform-description-2"
              defaults='Switch your <b>{{platform}}</b> account to <b>{{account}}</b> before click "Reconnect" button'
              values={{
                account: selectedPlatform?.username,
                platform: selectedPlatform?.platform,
              }}
              components={{
                b: <b className="font-bold" />,
              }}
            />
          </div>
        </div>
      </ModalV2>
    </>
  );
};
