import { createContext, useState, useCallback } from "react";
import { ConfirmModal } from "components/Modal/ConfirmModal";

export const ConfirmContext = createContext(null);

export const ConfirmProvider = ({ children }) => {
  const [state, setState] = useState({
    isOpen: false,
    title: "",
    description: "",
    onSubmit: () => {},
  });

  const onConfirm = useCallback((config) => {
    setState({ ...config, isOpen: true });
  }, []);

  const onClose = () => {
    setState((prev) => ({ ...prev, isOpen: false }));
  };

  return (
    <ConfirmContext.Provider value={{ onConfirm }}>
      {children}
      <ConfirmModal
        isOpen={state.isOpen}
        onClose={onClose}
        onSubmit={() => {
          state.onSubmit();
          onClose();
        }}
        title={state.title}
        description={state.description}
      />
    </ConfirmContext.Provider>
  );
};
