import { Close } from "components/icons";
import React from "react";
import { createPortal } from "react-dom";

export const ModalV2 = ({ isOpen, onClose, title, children, footer }) => {
  if (!isOpen) return null;

  const modalContent = (
    <div className="fixed inset-0 z-50 flex items-end justify-center md:items-center md:p-4">
      <div
        className="fixed inset-0 z-40 bg-black/50 transition-opacity"
        onClick={onClose}
      />

      <div
        className={`z-50 relative bg-third md:rounded-lg md:shadow-xl md:max-w-lg md:w-full md:max-h-[85vh] md:m-auto w-full mt-auto rounded-t-2xl max-h-[90vh] md:scale-100 flex flex-col`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="sticky top-0 flex items-center justify-between p-4 md:p-[24px] rounded-t-[16px] md:rounded-[16px]">
          <h3 className="text-lg font-semibold">{title}</h3>
          <div onClick={onClose}>
            <Close className="w-5 h-5 cursor-pointer" />
          </div>
        </div>
        <div
          className={`px-[16px] md:px-[24px] overflow-y-auto flex-1 ${
            footer ? "" : "pb-[16px] md:pb-[24px]"
          }`}
        >
          {children}
        </div>
        {footer && (
          <div className="sticky bottom-0 p-4 md:p-[24px] md:rounded-b-[16px]">
            {footer}
          </div>
        )}
      </div>
    </div>
  );

  return createPortal(modalContent, document.body);
};
