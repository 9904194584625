import { useCallback, useState } from "react";
import { Input, ModalV2, Button } from "components/common";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";

const queryFn = async (body) =>
  fetch("/api/private/platform/addToGroup", {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());

export const AddToGroupModal = ({ isOpen, onClose, selectedPlatforms }) => {
  const { t } = useTranslation();
  const [groupName, setGroupName] = useState("");

  const { mutate: addToGroup } = useMutation({
    mutationKey: ["addToGroup"],
    mutationFn: queryFn,
    onSuccess: () => {
      setGroupName("");
      onClose();
    },
  });

  const onSubmit = useCallback(() => {
    addToGroup({
      title: groupName,
      platforms: Object.keys(selectedPlatforms),
    });
  }, [addToGroup, groupName, selectedPlatforms]);

  return (
    <ModalV2
      title={t("add-to-group", "Add to Group")}
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <div className="flex justify-end">
          <Button onClick={onSubmit} className="w-full md:w-fit">
            {t("submit", "Submit")}
          </Button>
        </div>
      }
    >
      <div>
        <Input
          placeholder={t("my-group", "My Group")}
          label={t("group-name", "Group Name")}
          value={groupName}
          onChange={(value) => setGroupName(value)}
        />
      </div>
    </ModalV2>
  );
};
