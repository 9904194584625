import { useTranslation } from "react-i18next";
import React, { useState, useRef, useMemo } from "react";
import { Video } from "./icons";
import { PreviewModal } from "./Video/PreviewModal";
import { Button } from "./common";
import { toast } from "react-toastify";
import { usePlatformsPure } from "hooks/dev";

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

const debounce = (func, delay) => {
  let timeoutId;

  const debounced = (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };

  debounced.cancel = () => {
    clearTimeout(timeoutId);
  };

  return debounced;
};

const ThumbnailSelector = ({
  previewVideo,
  setVideoPreview,
  time,
  setTime,
}) => {
  const [thumbnails, setThumbnails] = useState([]);
  const videoRef = useRef();

  const handleCaptureFrame = () => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      const dataUrl = canvas.toDataURL("image/jpeg", 0.75);
      resolve(dataUrl);
    });
  };

  const handleCaptureCover = async () => {
    const frame = await handleCaptureFrame();
    setVideoPreview(frame);
  };

  const onLoadVideo = async () => {
    await sleep(50);
    await handleCaptureCover();
    const step = videoRef.current.duration / 8;
    const thumbnails = [];
    for (let i = 0; i < 8; i++) {
      const time = i * step;
      videoRef.current.currentTime = time;
      await sleep(50);
      const thumbnail = await handleCaptureFrame();
      thumbnails.push(thumbnail);
    }
    setThumbnails(thumbnails);
  };

  const debouncedPreviewChange = debounce(async (time) => {
    await handleCaptureCover();
  }, 100);

  const handleSliderChange = async (e) => {
    const newTime = parseFloat(e.target.value);
    setTime(newTime);
    videoRef.current.currentTime = newTime;
    await debouncedPreviewChange();
  };

  const handleTouchSliderChange = async (e) => {
    const touch = e.touches[0];
    const rect = e.target.getBoundingClientRect();
    const x = touch.clientX - rect.left;
    const percentage = x / rect.width;
    const newTime = percentage * videoRef.current.duration;
    setTime(newTime);
    videoRef.current.currentTime = newTime;
    await debouncedPreviewChange(newTime);
  };

  if (!previewVideo) return null;

  return (
    <div className="flex flex-col items-center">
      <div
        style={{
          display: "flex",
          overflowX: "scroll",
          width: "100%",
          position: "relative",
        }}
      >
        {thumbnails.map((thumbnail, index) => (
          <img
            key={index}
            src={thumbnail}
            alt={`Thumbnail ${index}`}
            style={{
              width: "12.5%",
              pointerEvents: "none", // Disable pointer events on thumbnails
            }}
          />
        ))}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: videoRef.current
              ? `${(time / videoRef.current.duration) * 100}%`
              : 0,
            height: "100%",
            width: "0",
            borderLeft: "4px solid #cdf560",
            zIndex: 2,
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          }}
        ></div>
      </div>
      {/* Invisible range input */}
      <input
        type="range"
        min={0}
        max={videoRef.current ? videoRef.current.duration : 0}
        step={0.01}
        value={time}
        onChange={handleSliderChange}
        onTouchStart={handleTouchSliderChange}
        onTouchMove={handleTouchSliderChange}
        style={{
          width: "100%",
          height: "100%",
          opacity: 0,
          position: "absolute",
          zIndex: 1,
        }}
      />
      <video
        autoPlay
        muted
        ref={videoRef}
        src={previewVideo}
        controls
        style={{ display: "none" }}
        onLoadedData={onLoadVideo}
      />
    </div>
  );
};

const MAX_DURATION = 60; // in seconds
const MAX_SIZE_MB = 150; // in megabytes
const TARGET_ASPECT_RATIO = 9 / 16; // 9:16
const ASPECT_RATIO_TOLERANCE = 0.1; // ±10%
const MIN_WIDTH = 540;
const MIN_HEIGHT = 960;

const VideoUploader = ({
  video,
  setVideo,
  preview,
  setPreview,
  time,
  setTime,
}) => {
  const { t } = useTranslation();
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false);
  const [previewVideo, setPreviewVideo] = useState();
  const { platforms } = usePlatformsPure();

  // const tiktokUser = useMemo(() => platforms["TikTok"].users?.[0], [platforms]);

  const validateVideoProperties = async (file) => {
    const video = document.createElement("video");
    video.src = URL.createObjectURL(file);

    const duration = await new Promise((resolve) => {
      video.onloadedmetadata = () => {
        resolve(video.duration);
      };
    });

    if (duration < 3 || duration > MAX_DURATION) {
      return {
        isValid: false,
        message: {
          key: "upload-error-duration",
          params: {
            defaultValue: "Video duration should be between 3 and 60 seconds",
          },
        },
      };
    }
    const fileSizeInMB = file.size / (1024 * 1024);
    if (fileSizeInMB > MAX_SIZE_MB) {
      return {
        isValid: false,
        message: {
          key: "upload-error-size",
          params: {
            defaultValue: "Video size exceeds the maximum allowed (150 MB)",
          },
        },
      };
    }
    if (video.videoWidth < MIN_WIDTH || video.videoHeight < MIN_HEIGHT) {
      return {
        isValid: false,
        message: {
          key: "upload-error-resolution",
          params: {
            defaultValue:
              "Video resolution should be at least {{minWidth}}x{{minHeight}} pixels",
            minWidth: MIN_WIDTH,
            minHeight: MIN_HEIGHT,
          },
        },
      };
    }
    const aspectRatio = video.videoWidth / video.videoHeight;
    const aspectRatioMin =
      TARGET_ASPECT_RATIO - TARGET_ASPECT_RATIO * ASPECT_RATIO_TOLERANCE;
    const aspectRatioMax =
      TARGET_ASPECT_RATIO + TARGET_ASPECT_RATIO * ASPECT_RATIO_TOLERANCE;

    if (aspectRatio < aspectRatioMin || aspectRatioMax < aspectRatio) {
      return {
        isValid: false,
        message: {
          key: "upload-error-ratio",
          params: {
            defaultValue:
              "Invalid video aspect ratio. Please use 9/16 with ±10% tolerance",
          },
        },
      };
    }
    return { isValid: true };
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    const { isValid: isValidVideo, message } = await validateVideoProperties(
      file
    );

    if (!isValidVideo) {
      toast.error(t(message.key, message.params));
      return;
    }

    setVideo(file);
    setPreviewVideo(URL.createObjectURL(file));
    setTime(0);
  };

  return (
    <div className="w-full flex justify-center md:justify-start md:block md:w-fit">
      <div className="relative w-[216px] h-[382px] border-2 border-line bg-white flex items-center justify-center hover:border-main overflow-hidden">
        {preview ? (
          <>
            <video
              className="absolute inset-0 w-full h-full object-cover"
              // controls={tiktokUser && false}
              poster={preview}
              src={previewVideo}
            >
              <source src={preview} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {/* <button
              className="absolute top-1 right-1 rounded-full bg-gray-400 text-main p-1"
              onClick={handleChooseAnother}
            >
              <Close className="text-white w-10 h-10" />
            </button> */}
            <button
              className="absolute top-0 w-full bg-[#00000070] font-bold p-1 text-white text-lg"
              onClick={() => setIsOpenPreviewModal(true)}
            >
              {t("select-preview", { defaultValue: "Select preview" })}
            </button>
          </>
        ) : (
          <label
            htmlFor="fileInput"
            className="cursor-pointer text-center w-full h-full flex justify-center items-center"
          >
            {video ? (
              <span>{video.name}</span>
            ) : (
              <span className="flex flex-col justify-center items-center">
                <Video className="w-10 h-10" />
                <div className="text-2xl font-semibold mt-2">
                  {t("upload-video", { defaultValue: "Upload Video" })}
                </div>
              </span>
            )}
          </label>
        )}
        <input
          type="file"
          accept="video/*"
          id="fileInput"
          className="hidden w-full h-full"
          onChange={handleFileChange}
        />
      </div>
      <PreviewModal
        isOpen={isOpenPreviewModal}
        onClose={() => setIsOpenPreviewModal(false)}
      >
        <div className="relative">
          <img src={preview} className="w-auto max-h-[500px]" />
          <div className="absolute bottom-0 border-t border-main">
            <ThumbnailSelector
              previewVideo={previewVideo}
              setVideoPreview={setPreview}
              time={time}
              setTime={setTime}
              isOpen={Boolean(isOpenPreviewModal) && Boolean(previewVideo)}
            />
          </div>
        </div>
        <Button
          className="w-full h-[60px]"
          onClick={() => setIsOpenPreviewModal(false)}
        >
          {t("apply", { defaultValue: "Apply" })}
        </Button>
      </PreviewModal>
    </div>
  );
};

export default React.memo(VideoUploader);
