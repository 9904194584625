import { useContext } from "react";
import { ConfirmContext } from "components/Providers/ConfirmProvider";

export const useConfirm = () => {
  const context = useContext(ConfirmContext);
  if (!context) {
    throw new Error("useConfirm must be used within ConfirmProvider");
  }
  return context.onConfirm;
};
