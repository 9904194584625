export const Repost = ({ className }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <path
        d="M29.0251 12.4622V6.46216C29.0251 5.90987 28.5774 5.46216 28.0251 5.46216C27.4729 5.46216 27.0251 5.90987 27.0251 6.46216V11.4622H22.0251C21.4729 11.4622 21.0251 11.9099 21.0251 12.4622C21.0251 13.0144 21.4729 13.4622 22.0251 13.4622H28.0251C28.5774 13.4622 29.0251 13.0144 29.0251 12.4622Z"
        fill="current"
      />
      <path
        d="M27.319 13.1705C27.5065 13.3574 27.7604 13.4622 28.0251 13.4622L28.0266 13.4623C28.2918 13.4619 28.546 13.3562 28.7332 13.1684C28.9201 12.9809 29.0251 12.7269 29.0251 12.4622L29.0251 12.4608C29.0247 12.1956 28.919 11.9414 28.7312 11.7542L24.4812 7.51666C22.7963 5.82931 20.5916 4.91514 20.5916 4.91514C18.3868 4.00098 16.0001 4.00098 16.0001 4.00098C13.6134 4.00098 11.4086 4.91514 11.4086 4.91514C9.20392 5.82931 7.51748 7.51822 7.51748 7.51822C7.33025 7.70571 7.2251 7.95985 7.2251 8.22481C7.22529 8.49003 7.33083 8.74503 7.51851 8.93243C7.706 9.11965 7.96013 9.22481 8.2251 9.22481C8.49031 9.22462 8.74532 9.11907 8.93272 8.9314C10.3378 7.52427 12.1747 6.76262 12.1747 6.76262C14.0116 6.00098 16.0001 6.00098 16.0001 6.00098C17.9886 6.00098 19.8255 6.76262 19.8255 6.76262C21.6624 7.52427 23.069 8.93296 23.069 8.93296L27.319 13.1705Z"
        fill="current"
      />
      <path
        d="M2.9751 19.5374V25.5374C2.9751 26.0896 3.42281 26.5374 3.9751 26.5374C4.52738 26.5374 4.9751 26.0896 4.9751 25.5374V20.5374H9.9751C10.5274 20.5374 10.9751 20.0896 10.9751 19.5374C10.9751 18.9851 10.5274 18.5374 9.9751 18.5374H3.9751C3.42281 18.5374 2.9751 18.9851 2.9751 19.5374Z"
        fill="current"
      />
      <path
        d="M8.93116 23.0667L4.68116 18.8292C4.49371 18.6423 4.2398 18.5374 3.9751 18.5374L3.97362 18.5374C3.70841 18.5377 3.45421 18.6435 3.26695 18.8313C3.08005 19.0187 2.9751 19.2726 2.9751 19.5374L2.9751 19.5388C2.97549 19.804 3.08122 20.0582 3.26903 20.2455L7.51903 24.483C9.20392 26.1704 11.4086 27.0845 11.4086 27.0845C13.6134 27.9987 16.0001 27.9987 16.0001 27.9987C18.3868 27.9987 20.5916 27.0845 20.5916 27.0845C22.7963 26.1704 24.4827 24.4814 24.4827 24.4814C24.6699 24.2939 24.7751 24.0398 24.7751 23.7749C24.7749 23.5096 24.6694 23.2546 24.4817 23.0672C24.2942 22.88 24.0401 22.7749 23.7751 22.7749C23.5099 22.775 23.2549 22.8806 23.0675 23.0683C21.6624 24.4754 19.8255 25.237 19.8255 25.237C17.9886 25.9987 16.0001 25.9987 16.0001 25.9987C14.0116 25.9987 12.1747 25.237 12.1747 25.237C10.3378 24.4754 8.93116 23.0667 8.93116 23.0667Z"
        fill="current"
      />
    </svg>
  );
};
