import React, { createContext, useState } from "react";

export const PaywallContext = createContext({
  showPaywall: () => {},
  hidePaywall: () => {},
  isOpen: false,
});

export const PaywallProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const showPaywall = () => setIsOpen(true);
  const hidePaywall = () => setIsOpen(false);

  return (
    <PaywallContext.Provider value={{ showPaywall, hidePaywall, isOpen }}>
      {children}
    </PaywallContext.Provider>
  );
};
