import { useEffect, useState, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Container, Loading } from "components/common";
import { Layout } from "components/common/Layout";
import { Check, Close, PlatformIcon } from "components/icons";
import { Pagination } from "components/common/Pagination";
import { useLng } from "hooks/dev";
import { useCancelSchedule } from "hooks/business";

const Image = ({ image, username, className }) =>
  image ? (
    <img
      className={[
        "w-[50px] h-[50px] min-w-[50px] min-h-[50px] rounded-full p-0.5",
        className,
      ]
        .filter(Boolean)
        .join(" ")}
      src={
        /^(https?:\/\/)/.test(image)
          ? image
          : `/uploads/platform-images/${image}`
      }
    />
  ) : (
    <div className="bg-main rounded-full border-2 border-third p-2 w-full h-full flex justify-center items-center text-acent text-sm">
      {username?.charAt(0)}
    </div>
  );

const Platform = ({ platform, status, image, username }) => {
  if (status === "SUCCESS" || status === "true" || status === undefined) {
    return (
      <div className="relative w-[50px] h-[50px] min-w-[50px] min-h-[50px] border-2 border-acent box-content rounded-full">
        <Image image={image} username={username} />
        <Check className="absolute -top-2 -right-2 w-7 h-7 text-main bg-acent rounded-full p-1" />
        {platform && (
          <PlatformIcon
            platform={platform}
            className="absolute -bottom-2 -right-2 w-8 h-8 text-main rounded-full"
          />
        )}
      </div>
    );
  }

  if (status === "PENDING") {
    return (
      <div className="relative w-[50px] h-[50px] min-w-[50px] min-h-[50px] border-2 border-transparent box-content rounded-full">
        <div className="absolute -top-[2px] -left-[2px] w-[54px] h-[54px] min-w-[50px] min-h-[50px] rounded-full border-2 border-solid border-gray-300 border-t-transparent animate-spin" />
        <Image image={image} username={username} className="grayscale" />
        {platform && (
          <PlatformIcon
            platform={platform}
            className="absolute -bottom-2 -right-2 w-8 h-8 text-main rounded-full"
          />
        )}
      </div>
    );
  }

  return (
    <div className="relative w-[50px] h-[50px] min-w-[50px] min-h-[50px] border-2 border-error box-content rounded-full">
      <Image image={image} username={username} />
      <Close className="absolute -top-2 -right-2 w-7 h-7 text-main bg-error rounded-full p-1" />
      {platform && (
        <PlatformIcon
          platform={platform}
          className="absolute -bottom-2 -right-2 w-8 h-8 text-main rounded-full"
        />
      )}
    </div>
  );
};

function useInterval(callback, delay, active) {
  const savedCallback = useRef();
  const [count, setCount] = useState(0);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
      setCount((prevCount) => prevCount + 1);
    }
    if (delay !== null && count < 25 && active) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay, count, active]);
}

const fn = (page) =>
  fetch(`/api/private/post/history?page=${page}`)
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        return res;
      }
      return { data: [], total: 0 };
    });

export const History = () => {
  const { t } = useTranslation();
  const { locale } = useLng();
  const [page, setPage] = useState(1);
  const {
    data: historyData = [],
    isLoading,
    refetch,
  } = useQuery(["GET_POSTS_HISTORY", page], () => fn(page));
  const { data, total } = historyData || {};
  const { onCancelSchedule } = useCancelSchedule();

  const isPending =
    !data?.[0]?.scheduled &&
    (data?.[0]?.result || []).some((el) => el.status === "PENDING");

  useInterval(() => refetch(), 10000, isPending);

  const body = () => {
    if (isLoading)
      return (
        <div className="w-fit">
          <Loading />
        </div>
      );

    if ((data || []).length === 0)
      return (
        <div className="flex flex-col justify-center items-center h-[400px] text-3xl font-bold">
          {t("empty-history", { defaultValue: "Empty history" })}
          <Link to="/post">
            <div className="bg-main px-4 py-1 text-base font-normal mt-2 text-acent rounded-full cursor-pointer">
              {t("post-first-video", { defaultValue: "Post your first video" })}{" "}
              →
            </div>
          </Link>
        </div>
      );

    return (
      <>
        <div className="flex flex-col gap-2">
          {(data || []).map((el, idx) => (
            <div
              className="flex border border-main w-full p-[10px] md:p-[20px]"
              key={idx}
            >
              <div className="mr-[10px]">
                <img
                  className="w-[108px] h-[192px] min-w-[108px] min-h-[192px]"
                  src={`/uploads/${el.filename}`}
                />
              </div>
              <div className="overflow-hidden w-full">
                <div className="flex gap-2 overflow-auto py-[20px] pr-[20px]">
                  {el.result.map((item, pidx) => (
                    <div key={pidx}>
                      <Platform
                        platform={item.platform}
                        status={item.status}
                        image={item.usernameImage}
                        username={item.username}
                      />
                    </div>
                  ))}
                </div>
                <div className="text-sm text-primary">
                  {el.scheduled ? (
                    <span>
                      <b className="mr-1">{t("scheduled", "Scheduled")}:</b>
                      {new Date(+el.scheduled).toLocaleDateString(locale, {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </span>
                  ) : (
                    new Date(el.date).toLocaleDateString(locale, {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                  )}
                </div>
                <div className="text-md mt-1 text-main">{el.description}</div>
                {el.scheduled ? (
                  <div className="flex w-full justify-end mt-1">
                    <div
                      onClick={() => onCancelSchedule(el.id)}
                      className="text-xs border border-error text-error rounded-md px-2 py-1 cursor-pointer hover:bg-error hover:text-third"
                    >
                      {t("cancel-shedule", "Cancel Schedule")}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </div>
        <div className="mt-4 flex justify-center mb-4">
          <Pagination
            onPageChange={setPage}
            totalCount={total || 0}
            currentPage={page}
            pageSize={10}
          />
        </div>
      </>
    );
  };

  return (
    <Layout>
      <Container>
        <div className="pt-[20px] flex-1">
          <div className="text-3xl text-main mb-4 font-bold">
            {t("history", { defaultValue: "History" })}
          </div>
          {body()}
        </div>
      </Container>
    </Layout>
  );
};
