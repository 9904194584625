export const Accounts = ({ className }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 16C10 16 12.0711 16 13.5355 17.4645C13.5355 17.4645 15 18.9289 15 21C15 21 15 23.0711 13.5355 24.5355C13.5355 24.5355 12.0711 26 10 26C10 26 7.92893 26 6.46447 24.5355C6.46447 24.5355 5 23.0711 5 21C5 21 5 18.9289 6.46447 17.4645C6.46447 17.4645 7.92893 16 10 16ZM10 18C10 18 8.75736 18 7.87868 18.8787C7.87868 18.8787 7 19.7574 7 21C7 21 7 22.2426 7.87868 23.1213C7.87868 23.1213 8.75736 24 10 24C10 24 11.2426 24 12.1213 23.1213C12.1213 23.1213 13 22.2426 13 21C13 21 13 19.7574 12.1213 18.8787C12.1213 18.8787 11.2426 18 10 18Z"
        fill="current"
      />
      <path
        d="M12.9069 26.6862C14.2793 27.3724 15.2 28.6 15.2 28.6C15.3591 28.8122 15.596 28.9524 15.8586 28.99C15.9054 28.9966 15.9527 29 16 29L16.0034 29C16.2186 28.9993 16.4278 28.9291 16.6 28.8C16.8518 28.6111 17 28.3148 17 28L16.9999 27.9869C16.9971 27.775 16.9271 27.5695 16.8 27.4C15.5961 25.7947 13.8013 24.8974 13.8013 24.8974C12.0066 24 10 24 10 24C7.99342 24 6.19868 24.8974 6.19868 24.8974C4.40395 25.7947 3.2 27.4 3.2 27.4C3.07018 27.5731 3 27.7836 3 28L3.00004 28.0088C3.00043 28.0532 3.00377 28.0975 3.01005 28.1414C3.04756 28.404 3.18783 28.6409 3.4 28.8C3.5731 28.9298 3.78363 29 4 29L4.0088 29C4.05318 28.9996 4.09749 28.9962 4.14142 28.9899C4.40397 28.9524 4.64087 28.8122 4.8 28.6C5.72067 27.3724 7.09311 26.6862 7.09311 26.6862C8.46556 26 10 26 10 26C11.5344 26 12.9069 26.6862 12.9069 26.6862Z"
        fill="current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3C10 3 12.0711 3 13.5355 4.46447C13.5355 4.46447 15 5.92893 15 8C15 8 15 10.0711 13.5355 11.5355C13.5355 11.5355 12.0711 13 10 13C10 13 7.92893 13 6.46447 11.5355C6.46447 11.5355 5 10.0711 5 8C5 8 5 5.92893 6.46447 4.46447C6.46447 4.46447 7.92893 3 10 3ZM10 5C10 5 8.75736 5 7.87868 5.87868C7.87868 5.87868 7 6.75736 7 8C7 8 7 9.24264 7.87868 10.1213C7.87868 10.1213 8.75736 11 10 11C10 11 11.2426 11 12.1213 10.1213C12.1213 10.1213 13 9.24264 13 8C13 8 13 6.75736 12.1213 5.87868C12.1213 5.87868 11.2426 5 10 5Z"
        fill="current"
      />
      <path
        d="M12.9069 13.6862C14.2793 14.3724 15.2 15.6 15.2 15.6C15.3591 15.8122 15.596 15.9524 15.8586 15.99C15.9054 15.9966 15.9527 16 16 16L16.0034 16C16.2186 15.9993 16.4278 15.9291 16.6 15.8C16.8518 15.6111 17 15.3148 17 15L16.9999 14.9869C16.9971 14.775 16.9271 14.5695 16.8 14.4C15.5961 12.7947 13.8013 11.8974 13.8013 11.8974C12.0066 11 10 11 10 11C7.99342 11 6.19868 11.8974 6.19868 11.8974C4.40395 12.7947 3.2 14.4 3.2 14.4C3.07018 14.5731 3 14.7836 3 15L3.00004 15.0088C3.00043 15.0532 3.00377 15.0975 3.01005 15.1414C3.04756 15.404 3.18783 15.6409 3.4 15.8C3.5731 15.9298 3.78363 16 4 16L4.0088 16C4.05318 15.9996 4.09749 15.9962 4.14142 15.9899C4.40397 15.9524 4.64087 15.8122 4.8 15.6C5.72067 14.3724 7.09311 13.6862 7.09311 13.6862C8.46556 13 10 13 10 13C11.5344 13 12.9069 13.6862 12.9069 13.6862Z"
        fill="current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 16C22 16 24.0711 16 25.5355 17.4645C25.5355 17.4645 27 18.9289 27 21C27 21 27 23.0711 25.5355 24.5355C25.5355 24.5355 24.0711 26 22 26C22 26 19.9289 26 18.4645 24.5355C18.4645 24.5355 17 23.0711 17 21C17 21 17 18.9289 18.4645 17.4645C18.4645 17.4645 19.9289 16 22 16ZM22 18C22 18 20.7574 18 19.8787 18.8787C19.8787 18.8787 19 19.7574 19 21C19 21 19 22.2426 19.8787 23.1213C19.8787 23.1213 20.7574 24 22 24C22 24 23.2426 24 24.1213 23.1213C24.1213 23.1213 25 22.2426 25 21C25 21 25 19.7574 24.1213 18.8787C24.1213 18.8787 23.2426 18 22 18Z"
        fill="current"
      />
      <path
        d="M24.9069 26.6862C26.2793 27.3724 27.2 28.6 27.2 28.6C27.3591 28.8122 27.596 28.9524 27.8586 28.99C27.9054 28.9966 27.9527 29 28 29L28.0034 29C28.2186 28.9993 28.4278 28.9291 28.6 28.8C28.8518 28.6111 29 28.3148 29 28L28.9999 27.9869C28.9971 27.775 28.9271 27.5695 28.8 27.4C27.5961 25.7947 25.8013 24.8974 25.8013 24.8974C24.0066 24 22 24 22 24C19.9934 24 18.1987 24.8974 18.1987 24.8974C16.4039 25.7947 15.2 27.4 15.2 27.4C15.0702 27.5731 15 27.7836 15 28L15 28.0088C15.0004 28.0532 15.0038 28.0975 15.0101 28.1414C15.0476 28.404 15.1878 28.6409 15.4 28.8C15.5731 28.9298 15.7836 29 16 29L16.0088 29C16.0532 28.9996 16.0975 28.9962 16.1414 28.9899C16.404 28.9524 16.6409 28.8122 16.8 28.6C17.7207 27.3724 19.0931 26.6862 19.0931 26.6862C20.4656 26 22 26 22 26C23.5344 26 24.9069 26.6862 24.9069 26.6862Z"
        fill="current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 3C22 3 24.0711 3 25.5355 4.46447C25.5355 4.46447 27 5.92893 27 8C27 8 27 10.0711 25.5355 11.5355C25.5355 11.5355 24.0711 13 22 13C22 13 19.9289 13 18.4645 11.5355C18.4645 11.5355 17 10.0711 17 8C17 8 17 5.92893 18.4645 4.46447C18.4645 4.46447 19.9289 3 22 3ZM22 5C22 5 20.7574 5 19.8787 5.87868C19.8787 5.87868 19 6.75736 19 8C19 8 19 9.24264 19.8787 10.1213C19.8787 10.1213 20.7574 11 22 11C22 11 23.2426 11 24.1213 10.1213C24.1213 10.1213 25 9.24264 25 8C25 8 25 6.75736 24.1213 5.87868C24.1213 5.87868 23.2426 5 22 5Z"
        fill="current"
      />
      <path
        d="M24.9069 13.6862C26.2793 14.3724 27.2 15.6 27.2 15.6C27.3591 15.8122 27.596 15.9524 27.8586 15.99C27.9054 15.9966 27.9527 16 28 16L28.0034 16C28.2186 15.9993 28.4278 15.9291 28.6 15.8C28.8518 15.6111 29 15.3148 29 15L28.9999 14.9869C28.9971 14.775 28.9271 14.5695 28.8 14.4C27.5961 12.7947 25.8013 11.8974 25.8013 11.8974C24.0066 11 22 11 22 11C19.9934 11 18.1987 11.8974 18.1987 11.8974C16.4039 12.7947 15.2 14.4 15.2 14.4C15.0702 14.5731 15 14.7836 15 15L15 15.0088C15.0004 15.0532 15.0038 15.0975 15.0101 15.1414C15.0476 15.404 15.1878 15.6409 15.4 15.8C15.5731 15.9298 15.7836 16 16 16L16.0088 16C16.0532 15.9996 16.0975 15.9962 16.1414 15.9899C16.404 15.9524 16.6409 15.8122 16.8 15.6C17.7207 14.3724 19.0931 13.6862 19.0931 13.6862C20.4656 13 22 13 22 13C23.5344 13 24.9069 13.6862 24.9069 13.6862Z"
        fill="current"
      />
    </svg>
  );
};
